/* Parallax Sections */
.parallax,
.reverse-parallax {
  padding-top: 60px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !;
  position: relative;
  overflow: hidden;
  will-change: transform; /* Optimizes performance */
}

.parallax::before,
.reverse-parallax::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transform: translateZ(
    0
  ); /* Creates a new stacking context for GPU acceleration */
}
/* Parallax Background Images */
.parallax {
  background-image: url("../../assets/1.jpg"); /* Replace with your image */
  height: 140vh;
}

.reverse-parallax {
  background-image: url("../../assets/2.jpg"); /* Replace with your image */
  background-size: 100% 250vh;
  height: 120vh;
}

/* Content Wrapper */
.content-wrapper {
  position: absolute; /* Ensures text stays in place */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Centering correction */
  text-align: center; /* Center text */
  color: white; /* Text color, adjust as needed */
}

/* Content Sections */
.content {
  padding-top: 0;
  /* height: 100vh; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* services */
.services {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-image: radial-gradient(
      circle at center center,
      rgba(33, 33, 33, 0),
      rgb(33, 33, 33)
    ),
    repeating-linear-gradient(
      135deg,
      rgb(33, 33, 33) 0px,
      rgb(33, 33, 33) 1px,
      transparent 1px,
      transparent 4px
    ),
    repeating-linear-gradient(
      45deg,
      rgb(56, 56, 56) 0px,
      rgb(56, 56, 56) 5px,
      transparent 5px,
      transparent 6px
    ),
    linear-gradient(90deg, rgb(33, 33, 33), rgb(33, 33, 33));
  padding: 40px 0;
}
.services h2 {
  font-family: var(--title-font);
  letter-spacing: 5px;

  font-size: 48px;
  text-align: center;
}

.service-text h3 {
  font-family: var(--hand-font);
  font-size: 50px;
  text-shadow: 2px 2px 0 blue, /* Outline color */ -2px -2px 0 blue,
    2px -2px 0 blue, -2px 2px 0 blue;
}

.service-text p {
  font-family: var(--body-font);
  font-size: 28px;
}

.service-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.service-item {
  color: white;
  font-size: 24px;
  font-family: var(--title-font);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button {
  text-decoration: none;
  font-family: var(--body-font);
  padding: 1em 2em;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  cursor: pointer;
  color: white !important;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid blue;
}

.button:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #70bdca;
  box-shadow: 4px 5px 17px -4px #268391;
}

.button::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: blue;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.button:hover::before {
  width: 250%;
}

.de-box-a {
  position: relative;
}
.de-box-a .d-image {
  position: relative;
  z-index: 1;
}
.de-box-a .d-image img {
  width: 325px;
  height: 280px;
  padding: 10%;
}

.de-box-a .d-deco-1 {
  top: 0;
  z-index: 2;
  right: 0;
  position: absolute;
  width: 50%;
  height: 50%;
  border: dotted 3px blue;
}

.de-box-a .d-deco-2 {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 50%;
  height: 50%;
  border: solid 3px blue;
}

.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.gallery h2 {
  font-family: var(--title-font);
  letter-spacing: 5px;

  font-size: 48px;
  text-align: center;
}

.gallery h3 {
  font-family: var(--hand-font);
  font-size: 50px;
  text-align: center;
  text-shadow: 2px 2px 0 blue, /* Outline color */ -2px -2px 0 blue,
    2px -2px 0 blue, -2px 2px 0 blue;
}
/* Gallery Section */
.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 20px;
}

.image-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.image-container img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.image-container img:hover {
  transform: scale(1.05);
}

.expanded-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.expanded-image {
  max-width: 60%;
  max-height: 60%;
  border-radius: 10px;
}

.intro {
  padding-top: 150px;
  display: flex;
  gap: 100px;
  padding-bottom: 50px;
}
.intro-text {
  font-family: var(--body-font);
  font-size: 26px;
  padding-bottom: 40px;
}
.hero {
  /* padding-top: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
}
.hero img {
  width: 65%;
}
.animated-jack-pulse {
  /* Apply the jack-in-the-box animation with a 3s delay */
  animation: jackInTheBox 2s 0.1s forwards, pulse ease-in-out 1s 4s 3;
}

/* Updated Jack in the Box animation keyframes with rotation */
@keyframes jackInTheBox {
  0% {
    transform: scale(0) rotate(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1) rotate(15deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}

/* Pulse animation keyframes */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.looping_text {
  display: flex;
  justify-content: center;
  align-content: center;
  font-family: var(--hand-font);
  font-size: 9rem;
  color: white; /* Text color */
  text-shadow: 2px 2px 0 blue, /* Outline color */ -2px -2px 0 blue,
    2px -2px 0 blue, -2px 2px 0 blue;
}

.contact {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-image: repeating-linear-gradient(
    90deg,
    rgb(3, 3, 3) 0px,
    rgb(3, 3, 3) 18px,
    rgb(11, 11, 11) 18px,
    rgb(11, 11, 11) 36px,
    rgb(0, 0, 0) 36px,
    rgb(0, 0, 0) 54px,
    rgb(5, 5, 5) 54px,
    rgb(5, 5, 5) 72px,
    rgb(8, 8, 8) 72px,
    rgb(8, 8, 8) 90px,
    rgb(14, 14, 14) 90px,
    rgb(14, 14, 14) 108px,
    rgb(19, 19, 19) 108px,
    rgb(19, 19, 19) 126px,
    rgb(16, 16, 16) 126px,
    rgb(16, 16, 16) 144px
  );
  padding: 40px 0;
}

.contact h2 {
  font-family: var(--title-font);
  letter-spacing: 5px;

  font-size: 48px;
  text-align: center;
}
.contact-text {
  font-family: var(--body-font);
  font-size: 28px;
}

@media screen and (max-width: 768px) {
  .looping_text {
    font-size: 5rem;
  }
  .intro {
    gap: 30px;
  }
  .intro-text {
    font-family: var(--body-font);
    font-size: 26px;
    padding-left: 25px;
    padding-right: 25px;

    padding-bottom: 40px;
  }

  .service-grid {
    grid-template-columns: 1fr 1fr;
  }

  .de-box-a .d-image img {
    width: 225px;
    height: 180px;
    padding: 10%;
  }

  .service-text p {
    font-size: 18px;
  }

  .reverse-parallax {
    background-size: 300vw 550vh;
    height: 180vh;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 5px;
    padding: 10px;
  }

  .image-container img {
    width: 100%;
    height: auto;
  }
  .expanded-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .expanded-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
  }
  .contact-text {
    font-size: 18px;
  }
}
