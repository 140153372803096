.form {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: var(--title-font);
  gap: 10px;
  padding-inline: 2em;
  padding-bottom: 0.4em;
  background-color: #171717;
  width: 80vh;

  border-radius: 20px;
}

.form-heading {
  text-align: center;
  margin: 1.5em;
  color: blue;
  font-size: 2.2em;
  background-color: transparent;
  align-self: center;
}

.form-field {
  font-family: var(--body-font);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 10px;
  padding: 0.6em;
  border: none;
  outline: none;
  color: white;
  background-color: #171717;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}

.input-field {
  background: none;
  border: none;
  outline: none;
  height: 60px;
  width: 100%;
  color: #ccd6f6;
  padding-inline: 1em;
}

.sendMessage-btn {
  cursor: pointer;
  margin-bottom: 3em;
  padding: 1em;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  color: blue;
  font-weight: bold;
  outline: 1px solid blue;
  transition: all ease-in-out 0.3s;
}

.sendMessage-btn:hover {
  transition: all ease-in-out 0.3s;
  background-color: blue;
  color: white;
  cursor: pointer;
  box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
}

.form-card1 {
  background-image: linear-gradient(163deg, blue 0%, blue 100%);
  border-radius: 22px;
  transition: all 0.3s;
}

.form-card1:hover {
  box-shadow: 0px 0px 30px 1px rgba(35, 35, 225, 0.3);
}

.form-card2 {
  border-radius: 0;
  transition: all 0.2s;
}

.form-card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
}

.error {
  color: red;
  text-align: center;
}

.success {
  color: green;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .form {
    width: 100%;
  }
}
