/* booking.css */

/* General styling for the booking container */
.booking {
  padding: 20px;
  background-image: url("../../assets/4.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.booking-intro {
  padding-top: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.booking-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 40px;
}
.booking-text h1 {
  font-family: var(--title-font);
  letter-spacing: 5px;
  color: white;

  font-size: 48px;
  text-align: center;
}

.booking-text h2 {
  font-family: var(--hand-font);
  color: white;
  text-align: center;
  font-size: 50px;
  text-shadow: 2px 2px 0 blue, /* Outline color */ -2px -2px 0 blue,
    2px -2px 0 blue, -2px 2px 0 blue;
}

.booking-text p {
  font-family: var(--body-font);
  font-size: 28px;
  color: white;
}

/* Calendar styling */
.calendar-container {
  margin-bottom: 20px;
  color: black;
}

/* Dark Theme for React Calendar */

/* General Calendar Styling */
.react-calendar {
  background-color: #333; /* Dark background */
  color: #fff; /* Light text color */
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* Navigation buttons */
.react-calendar__navigation button {
  color: #fff; /* Button text color */
  background-color: #444; /* Button background color */
  border: none;
  outline: none;
  cursor: pointer;
}

.react-calendar__navigation button:hover {
  background-color: #555; /* Hover state */
}

/* Month and Year view */
.react-calendar__tile {
  color: #fff; /* Tile text color */
  background-color: #444; /* Tile background color */
}

.react-calendar__tile:disabled {
  background-color: #222; /* Disabled tile background */
  color: #666; /* Disabled tile text color */
}

/* Selected date styling */
.react-calendar__tile--active {
  background-color: #66c2ff; /* Active date background */
  color: #000; /* Active date text color */
}

/* Hovered tile styling */
.react-calendar__tile:hover {
  background-color: #555; /* Hover state */
}

/* Current day styling */
.react-calendar__tile--now {
  background-color: #007bff; /* Current day background */
  color: #fff; /* Current day text color */
}

/* Other month tiles (for days not in the current month) */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #777; /* Lighter text for neighboring months */
}

/* Weekdays header styling */
.react-calendar__month-view__weekdays {
  background-color: #444; /* Background color for weekdays */
  color: #fff; /* Text color for weekdays */
}

/* Time slots container */
.appointment h3 {
  font-family: var(--title-font);
  letter-spacing: 5px;
  color: white;

  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.time-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
.time-slots p {
  color: white;
  font-family: var(--body-font);
  font-weight: bold;
  font-size: 16px;
}

/* Time slot button styling */

.time-slots button {
  text-decoration: none;
  font-family: var(--body-font);

  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 5px;
  text-transform: uppercase;
  cursor: pointer;
  color: rgb(53, 53, 53);
  transition: all 600ms;
  font-size: 11px;
  position: relative;
  overflow: hidden;
  outline: 2px solid blue;
}

.time-slots button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50px;
  width: 0;
  height: 100%;
  background-color: blue;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.time-slots button:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #70bdca;
  box-shadow: 4px 5px 17px -4px #268391;
}
.time-slots button:hover::before {
  width: 250%;
}

.time-slots button.selected {
  background-color: #28a745;
  color: white;
}
.time-slots .selected button:hover {
  background-color: #28a745 !important;
  color: white;
}

/* Form styling */
.booking form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.booking form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.booking form textarea {
  color: black; /* Change the placeholder color */
  font-family: var(--body-font);
  font-size: 24px;
  padding: 10px;
}
.booking form textarea::placeholder {
  color: gray; /* Change the placeholder color */
  font-family: var(--body-font);
  font-size: 24px;
  font-style: italic; /* Make the placeholder text italic */
  padding: 10px;
}

.booking form button {
  padding: 10px 15px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.booking form button:hover {
  background-color: #218838;
}

.booking form button {
  outline: none;
  cursor: pointer;
  border: none;
  padding: 0.9rem 2rem;
  margin: 0;
  font-family: var(--body-font);
  font-size: inherit;
  position: relative;
  display: inline-block;
  letter-spacing: 0.05rem;
  font-weight: 700;
  font-size: 17px;
  border-radius: 500px;
  overflow: hidden;
  background: #66ff66;
  color: ghostwhite;
}

.booking form button span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
}

.booking form button:hover span {
  color: white;
}

.booking form button::before,
.booking form button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.booking form button::before {
  content: "";
  background: blue;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.8s cubic-bezier(0.3, 1, 0.8, 1);
}

.booking form button:hover::before {
  transform: translate3d(100%, 0, 0);
}

/* Booking status message styling */
.booking-status {
  font-family: var(--body-font);
  font-size: 20px;
  text-align: center;
  color: green;
  margin-top: 20px;
  font-weight: bold;
  font-style: italic;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .booking-intro {
    flex-direction: column;
    padding-top: 100px;
    gap: 20px;
  }

  .calendar-container {
    width: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .booking-text h1 {
    font-size: 32px;
  }

  .booking-text h2 {
    font-size: 36px;
  }

  .booking-text p {
    font-size: 18px;
  }

  .time-slots {
    padding: 0;
  }

  .time-slots button {
    width: 100%;
    max-width: 120px;
    font-size: 14px;
  }

  .appointment h3 {
    font-size: 22px;
  }

  .booking form {
    max-width: 100%;
    padding: 0 15px;
  }

  .booking form button {
    width: 100%;
  }
}
