/* General container styling */
.admin {
  padding: 20px;
  padding-top: 100px;
  background-image: url("../../assets/5.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin h1 {
  font-family: var(--title-font);
  letter-spacing: 5px;
  color: white;
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
}
.admin h2 {
  font-family: var(--title-font);

  color: white;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

/* Table styling */
table {
  width: 100%;
  font-family: var(--body-font);
  border-collapse: collapse;
  margin-bottom: 20px;
}

table thead {
  background-color: #333;
  color: #fff;
}

table thead th {
  padding: 10px;
  text-align: left;
}

table tbody tr {
  background-color: #fff;
  transition: background-color 0.3s;
}

table tbody tr:hover {
  background-color: #f1f1f1;
}

table tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Invoice form styling */
.invoice-form {
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;
  font-family: var(--body-font);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.invoice-form h3 {
  margin-bottom: 15px;
  color: #333;
}

.invoice-form p {
  margin-bottom: 10px;
  color: #555;
}

.invoice-form input[type="number"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Loading and error messages */
.loading,
.error {
  text-align: center;
  font-size: 16px;
  color: #333;
  padding: 10px 0;
}

.error {
  color: #e74c3c;
}

.cancelled-btn {
  background-color: red;
}
.completed-btn {
  background-color: green;
}

/* Responsive styling for mobile */
@media (max-width: 768px) {
  .admin {
    padding: 10px;
    padding-top: 100px;
  }

  .admin h1 {
    font-size: 32px;
  }

  /* Make table display vertically */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  table {
    border: none;
  }

  table thead {
    display: none;
  }

  table tbody tr {
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
  }

  table tbody td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: none;
    text-align: left;
  }

  table tbody td:before {
    content: attr(data-label);
    flex: 1;
    font-weight: bold;
    color: #333;
  }

  button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .invoice-form {
    max-width: 100%;
    padding: 10px;
  }

  .invoice-form h3 {
    font-size: 18px;
  }

  .invoice-form input[type="number"] {
    padding: 6px;
  }
}
