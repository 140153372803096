.footer {
  background-image: radial-gradient(
      circle at center center,
      transparent,
      rgb(33, 33, 33)
    ),
    repeating-linear-gradient(
      135deg,
      rgb(33, 33, 33) 0px,
      rgb(33, 33, 33) 2px,
      transparent 2px,
      transparent 10px,
      rgb(33, 33, 33) 10px,
      rgb(33, 33, 33) 11px,
      transparent 11px,
      transparent 21px
    ),
    repeating-linear-gradient(
      45deg,
      rgb(47, 47, 47) 0px,
      rgb(47, 47, 47) 4px,
      transparent 4px,
      transparent 8px
    ),
    linear-gradient(90deg, rgb(33, 33, 33), rgb(33, 33, 33));
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 120px;
  padding-right: 80px;
  align-items: center;
  position: relative;
  margin-top: auto;
}

.footer-logo {
  position: relative;
  z-index: 10;
  width: 120px;
}

.footer h2 {
  font-family: var(--title-font);
  letter-spacing: 5px;
  color: white;
  font-size: 32px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
  }
}
