/* Barber Pole Container */
.barber-pole-container {
  position: relative;
  height: 280px; /* Adjust height as needed */
  width: 60px; /* Adjust width as needed */
  margin: 50px auto; /* Center the pole horizontally */
}

/* Barber Pole */
.barber-pole {
  height: 100%;
  width: 100%;
  background-color: #333; /* Background color of the pole */
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: repeating-linear-gradient(
    45deg,
    #333 0%,
    #333 16.66%,
    white 16.66%,
    white 33.33%,
    blue 33.33%,
    blue 50%
  );
  background-size: 100% 300%; /* Extend the size of the background */
  animation: spin 8s linear infinite; /* Smooth and slow spin */
}

/* Ball Handles */
.barber-pole-ball {
  width: 40px; /* Size of the ball handle */
  height: 40px; /* Size of the ball handle */
  border: 1px solid blue;
  background-image: linear-gradient(
      216deg,
      rgba(77, 77, 77, 0.05) 0%,
      rgba(77, 77, 77, 0.05) 25%,
      rgba(42, 42, 42, 0.05) 25%,
      rgba(42, 42, 42, 0.05) 38%,
      rgba(223, 223, 223, 0.05) 38%,
      rgba(223, 223, 223, 0.05) 75%,
      rgba(36, 36, 36, 0.05) 75%,
      rgba(36, 36, 36, 0.05) 100%
    ),
    linear-gradient(
      44deg,
      rgba(128, 128, 128, 0.05) 0%,
      rgba(128, 128, 128, 0.05) 34%,
      rgba(212, 212, 212, 0.05) 34%,
      rgba(212, 212, 212, 0.05) 57%,
      rgba(25, 25, 25, 0.05) 57%,
      rgba(25, 25, 25, 0.05) 89%,
      rgba(135, 135, 135, 0.05) 89%,
      rgba(135, 135, 135, 0.05) 100%
    ),
    linear-gradient(
      241deg,
      rgba(55, 55, 55, 0.05) 0%,
      rgba(55, 55, 55, 0.05) 14%,
      rgba(209, 209, 209, 0.05) 14%,
      rgba(209, 209, 209, 0.05) 60%,
      rgba(245, 245, 245, 0.05) 60%,
      rgba(245, 245, 245, 0.05) 69%,
      rgba(164, 164, 164, 0.05) 69%,
      rgba(164, 164, 164, 0.05) 100%
    ),
    linear-gradient(
      249deg,
      rgba(248, 248, 248, 0.05) 0%,
      rgba(248, 248, 248, 0.05) 32%,
      rgba(148, 148, 148, 0.05) 32%,
      rgba(148, 148, 148, 0.05) 35%,
      rgba(202, 202, 202, 0.05) 35%,
      rgba(202, 202, 202, 0.05) 51%,
      rgba(181, 181, 181, 0.05) 51%,
      rgba(181, 181, 181, 0.05) 100%
    ),
    linear-gradient(92deg, hsl(214, 0%, 11%), hsl(214, 0%, 11%)); /* Color of the ball handle */
  border-radius: 50%; /* Make it a perfect circle */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

/* Position the ball at the top */
.barber-pole-ball.top {
  top: -50px; /* Adjust position as needed */
}

/* Position the ball at the bottom */
.barber-pole-ball.bottom {
  bottom: -50px; /* Adjust position as needed */
}

/* Animation for Spinning */
@keyframes spin {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 0% 100%;
  }
}
