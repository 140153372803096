.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  padding: 10px 20px;
  box-sizing: border-box;
}

.navbar-logo {
  position: relative;
  z-index: 10;
  width: 120px;
}

.nav-links {
  display: flex;
  list-style: none;
}

.nav-links li {
  color: white;
  cursor: pointer;
  font-size: 24px;
  font-family: var(--title-font);
  margin: 0 15px;
  transition: 0.5s ease-in-out;
  display: flex;
  align-items: center;
}

.nav-links li:hover {
  color: blue;
}

.nav-links a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
}

.menu-icon {
  position: relative;
  z-index: 10;
  display: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.nav-links-mobile {
  display: none;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  position: absolute;
  z-index: 5;
  top: 20px;
  right: 0;
  width: 60%;
  height: 80%;
  padding: 20px;
  box-sizing: border-box;
}

.mobile-nav-bg {
  border: 0.5px solid white;
  background-color: #333;
  height: 80vh;
}

.nav-links-mobile li {
  font-size: 24px;
  font-family: var(--title-font);
  color: whitesmoke;
  cursor: pointer;
  text-align: center;
  transition: 0.5s ease-in-out;
  margin: 10px 0;
  list-style: none;
}

.booking-btn {
  font-size: 24px;
  font-family: var(--title-font);
  background-color: blue;
  padding: 10px 20px;
  border: 1px solid white;
  color: white;
  border-radius: 8px;
  position: relative;
  top: -10px;
  transition: 0.2s ease-in-out;
}

.booking-btn:hover {
  background-color: white;
  padding: 10px 20px;
  border: 1px solid blue;
  color: blue;
}

.admin-btn {
  font-size: 24px;
  font-family: var(--title-font);
  background-color: red;
  padding: 10px 20px;
  border: 1px solid white;
  color: white;
  border-radius: 8px;
  position: relative;
  top: -10px;
  transition: 0.2s ease-in-out;
}

.admin-btn:hover {
  background-color: white;
  padding: 10px 20px;
  border: 1px solid red;
  color: red !important;
}

.signout {
  position: relative;
  top: -10px;
}

.nav-links-mobile li:hover {
  color: blue;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .nav-links-mobile {
    display: flex;
  }
}
