.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.scroll-button {
  background-color: blue;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  padding: 15px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0.7;
}

.scroll-button:hover {
  opacity: 1;
}

.scroll-button:focus {
  outline: none;
}
