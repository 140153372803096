.register {
  padding: 150px 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("../../assets/2.jpg");
  background-size: cover;
}
.register-text {
  color: white;
  text-align: center;
  padding: 50px;
  flex-basis: 800px;
}

.register-text h1 {
  font-family: var(--title-font);
  letter-spacing: 5px;
  color: white;

  font-size: 48px;
  text-align: center;
}

.register-text h2 {
  font-family: var(--hand-font);
  color: white;
  text-align: center;
  font-size: 50px;
  text-shadow: 2px 2px 0 blue, /* Outline color */ -2px -2px 0 blue,
    2px -2px 0 blue, -2px 2px 0 blue;
}

.register-text p {
  font-family: var(--body-font);
  font-size: 16px;
}

.form-register {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 30px;
  width: 450px;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form-register ::placeholder {
  font-family: var(--body-font);
}

.form-register button {
  align-self: flex-end;
}

.form-register .flex-column > label {
  color: #151717;
  font-weight: 600;
}

.form-register .inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
}

.form-register .input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 100%;
}

.form-register .input:focus {
  outline: none;
}

.form-register .inputForm:focus-within {
  border: 1.5px solid #2d79f3;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.form-register .flex-row > div > label {
  font-size: 14px;
  color: white;
  font-weight: 400;
}

.form-register .span {
  font-size: 14px;
  margin-left: 5px;
  color: #2d79f3;
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  margin: 20px 0 10px 0;
  background-color: blue;
  border: none;
  color: white;
  font-family: var(--body-font);
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.button-submit:hover {
  background-color: white;
  border: 1px blue solid;
  color: blue;
}

.form-register .p {
  text-align: center;
  color: #111;
  font-size: 14px;
  margin: 5px 0;
}

.btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid #ededef;
  background-color: white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.btn:hover {
  border: 1px solid #2d79f3;
}

.password-toggle-icon {
  cursor: pointer;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .register {
    flex-direction: column;
  }
  .register-text {
    flex-basis: 460px;
  }
}
